export default ({ contentBySku: existingContentBySku }, sku, content) => {
  const contentBySku = { ...existingContentBySku };

  if (!contentBySku[sku]) {
    contentBySku[sku] = [];
  }

  const existingIndex = getExistingIndex(content, contentBySku[sku]);


  if (!!~existingIndex) {
    contentBySku[sku][existingIndex] = content;
  } else {
    contentBySku[sku].push(content);
  }

  contentBySku[sku].sort((a, b) => (a.weight > b.weight ? 1 : -1));

  return { contentBySku };
};

function getExistingIndex(content, existingContent) {
  return existingContent.findIndex(({ __id }) => __id == content.__id);
}
