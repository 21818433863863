import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';

import { SimpleMenu, MenuItem } from 'rmwc/Menu';
import { IconButton } from 'rmwc/IconButton';

import copyToClipboard from '../../utilities/copy-to-clipboard';
import downloadUri from '../../utilities/download-uri';

import '@material/menu-surface/dist/mdc.menu-surface.min.css';
import '@material/menu/dist/mdc.menu.min.css';
import '@material/icon-button/dist/mdc.icon-button.min.css';

import './images.css';

const rotationClasses = [
  'zero-degrees',
  'ninety-degrees',
  'one-eighty-degrees',
  'two-seventy-degrees',
];

export class ImageDetail extends React.Component {
  constructor() {
    super();

    this.state = {
      rotation: 0,
    };
  }

  get rotationClass() {
    return rotationClasses[this.state.rotation];
  }

  rotate() {
    const { rotation } = this.state;

    this.setState({ rotation: (rotation + 1) % 4 });
  }

  handleImgClick(e) {
    e.stopPropagation();

    this.rotate();
  }

  render() {
    const { src, setImageDetailSrc } = this.props;

    return (
      src && (
        <div className="image-detail" onClick={e => !isMenuClick(e.target) && setImageDetailSrc()}>
          <img src={src} className={this.rotationClass} onClick={this.handleImgClick.bind(this)} />

          <div className="menu-wrapper">
            <SimpleMenu
              handle={<IconButton icon="more_vert" />}
              onSelect={handleSelected({ src, setImageDetailSrc })}
            >
              <MenuItem>Copy URL</MenuItem>
              <MenuItem>Download</MenuItem>
              <MenuItem>Close</MenuItem>
            </SimpleMenu>
          </div>
        </div>
      )
    );
  }
}

export default connect(
  '',
  actions
)(ImageDetail);

function isMenuClick(el) {
  let result = false;

  if (el.classList.contains('menu-wrapper')) {
    result = true;
  } else if (!el.classList.contains('image-detail')) {
    result = isMenuClick(el.parentElement);
  }

  return result;
}

function handleSelected({ src, setImageDetailSrc }) {
  return e => {
    switch (e.detail.index) {
      case 0:
        copyToClipboard(src);
        break;
      case 1:
        downloadUri(src);
        break;
      case 2:
        setTimeout(() => setImageDetailSrc(), 300);
        break;
      default:
        console.info('image-detail handleSelected fell through!');
        break;
    }
  };
}
