import React from 'react';
import Head from 'next/head';

export default () => {
  return (
    <Head>
      {/* <link rel="stylesheet" href="/static/styles/fonts.css" /> */}
      {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" /> */}
    </Head>
  );
};
