import createStore from 'unistore';
import * as rawActions from './actions';
import devEnvironment from '../environments/environment.dev';
import prodEnvironment from '../environments/environment';

import syncMessagingTokens from './sync/sync-messaging-tokens';

const LOCALSTORAGE_NAME = 'calligraphy-dot-org-state';
const LOCALSTORAGE_DEBUG_NAME = 'calligraphy-dot-org-debug';
const nodeEnv = process.env.NODE_ENV;
// Use bin/build/make-prod.js to convert prodFlag to IS_PRODUCTION for production deploys
const prodFlag = 'X_IS_PRODUCTION';
const isDevelopment = prodFlag.length != 'IS_PRODUCTION'.length;

const environment = isDevelopment ? devEnvironment : prodEnvironment;
const VERSION = `cache-version-${environment.version}`;

let localStorageState = getLocalStorage() || {};

if (localStorageState.VERSION != VERSION) {
  console.info('refreshing localStorage', VERSION);
  localStorageState = {};
}

const initialState = {
  adminTabIndex: 0,
  claims: {},
  currentUser: {},
  contentBySku: {},
  debug: false,
  detailUserId: '',
  isSSR: typeof window != 'object',
  isDevelopment,
  isSubscribedToFCM: false,
  fcmPromptTimestamp: null,
  nodeEnv,
  router: {},
  settings: {},
  subscribedToMessages: false,
  user: {
    claims: {},
  },
  VERSION,
  ...localStorageState,
  // Not overridden by localStorageState 👇
  beforeInstallEvent: null,
  environment,
  imageDetailSrc: '',
  isDrawerOpen: false,
  loaded: false,
  messagingToken: null,
  notifications: [],
  pathname: '/',
  presence: false,
  query: null,
  serviceWorkerRegistered: false,
  subscriptions: [],
};

const store = createStore(initialState);

const actions = store => rawActions;

const mappedActions = {};
for (let i in rawActions) {
  mappedActions[i] = store.action(rawActions[i]);
}

setWindowState();
store.subscribe(setWindowState);

function setWindowState() {
  if (typeof window == 'object') {
    window.state = store.getState();
  }
}

store.subscribe(() => setLocalStorage(store.getState()));
store.subscribe(syncMessagingTokens(mappedActions, store));
// store.subscribe(() => console.info(store.getState()));

function getLocalStorage() {
  let result = {};

  if (typeof window == 'object') {
    const stringified = localStorage.getItem(LOCALSTORAGE_NAME);

    if (stringified) {
      result = JSON.parse(stringified);
    }
  }

  return result;
}

function setLocalStorage(state) {
  localStorage && localStorage.setItem(LOCALSTORAGE_NAME, JSON.stringify(state));
  localStorage && localStorage.setItem(LOCALSTORAGE_DEBUG_NAME, String(state.debug));
}

export { actions, mappedActions, store, LOCALSTORAGE_DEBUG_NAME };
