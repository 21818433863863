import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';
import gtagSet from "../../utilities/analytics/gtag-set";
import gtagPageview from '../../utilities/analytics/gtag-pageview';
import debounce from '../../utilities/debounce';

export class Pageview extends React.Component {
  constructor() {
    super();

    this.__gtagPageview = debounce(gtagPageview, 1000);
  }
  componentDidMount() {
    const {
      analytics: { trackingId },
    } = this.props.environment;

    this.__gtagPageview(trackingId);
  }

  componentDidUpdate(prevProps) {
    this.onChange('loaded', prevProps, loaded => {
      const { claims, currentUser, environment } = this.props;
      const trackingId = environment.analytics.trackingId;
      const user_id = currentUser && currentUser.uid;
      const isAdmin = claims && claims.isAdmin;
      const isModerator = claims && claims.isModerator;
      const dimension1 = isAdmin || isModerator ? 'not-user' : 'user';

      gtagSet({ custom_map: { dimension1 } });
      gtagSet({ user_id });

      this.__gtagPageview(trackingId);
    });
  }

  onChange(propName, prevProps, fn) {
    if (prevProps[propName] != this.props[propName]) {
      fn(this.props[propName]);
    }
  }

  render() {
    return null;
  }
}

export default connect(
  'claims,currentUser,environment,loaded,pathname',
  actions
)(Pageview);
