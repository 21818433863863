import React from 'react';

export default ({ firebaseEnv }) => {
  return (
    <>
      <script src="https://browser.sentry-cdn.com/4.3.0/bundle.min.js" crossOrigin="anonymous" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          Sentry.init({
            dsn: "https://8f6a724d513548febf7b99a38588e0e5@sentry.io/1321187"
           });
          `,
        }}
      />
    </>
  );
};
