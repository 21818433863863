/* globals firebase */
import React from 'react';
import { withRouter } from 'next/router';
import { Provider } from 'unistore/react';
import { mappedActions, store } from '../../datastore';

// Head tags
import AnalyticsHead from '../head/analytics';
import FacebookHead from '../head/facebook';
import FontsHead from '../head/fonts';
import MetaHead from '../head/meta';
import { AppStyle } from '../head/styles';

// Components
import ErrorBoundary from './error-boundary';
import Pageview from '../analytics/pageview';
import Authentication from '../authentication/authentication';
import PrimaryAppBar from '../app-bar/primary-app-bar';
import PermanentDrawer from '../drawers/permanent-drawer';
import TemporaryDrawer from '../drawers/temporary-drawer';
import AlertHandler from '../handlers/alert-handler';
import ErrorHandler from '../handlers/error-handler';
import FirebaseScripts from './firebase';
import SentryScripts from './sentry';
import AlgoliaScripts from './algolia';
import Content from './content';
import Messaging from './messaging';
import SyncQueryParams from './sync-query-params';
import PWA from './pwa';
// import Mailerlite from './mailerlite';

// Subscriptions
import NotificationsSubscription from '../subscriptions/notifications-subscription';
import PresenceSubscription from '../subscriptions/presence-subscription';
import SettingsSubscription from '../subscriptions/settings-subscription';
import UserSubscription from '../subscriptions/user-subscription';
import SubscriptionsSubscription from '../subscriptions/subscriptions-subscription';

// Utilities
import parseSearch from '../../utilities/parse-search';

import './app-shell.css';

export class AppShell extends React.Component {
  constructor() {
    super();
    this.state = store.getState();
  }

  get firebase() {
    return firebase;
  }

  get search() {
    return parseSearch(location.search);
  }

  componentDidMount() {
    mappedActions.setRouter(this.props.router);
    mappedActions.setPathname(this.props.router.pathname);
    this.setQuery();
  }

  componentDidUpdate(prevProps) {
    const prevQuery = JSON.stringify(prevProps.router.query);
    const query = JSON.stringify(this.search);

    prevQuery != query && this.setQuery();
  }

  setQuery() {
    mappedActions.setQuery(this.search);
  }

  render() {
    const {
      admin,
      algolia,
      children,
      description,
      isLandingPage,
      secure,
      seoTitle,
      title,
      url,
    } = this.props;
    const defaultTitle = 'Calligraphy.org - Online Calligraphy Classes - Beginners Welcome';
    const defaultDescription =
      'Modern & Brush Calligraphy for Beginners ✍🏼  The #1 online calligraphy class - Taught by industry pros - Learn at your own pace with one-on-one coaching';
    const defaultAppBarTitle = (
      <img
        src="https://firebasestorage.googleapis.com/v0/b/quiver-four.appspot.com/o/marketing%2Fx200%2Fcalligraphyorg-new-logo.png?alt=media&token=69a1a2ca-2535-460f-8edf-b514e1a9a95a"
        alt="Calligraphy.org"
      />
    );

    return (
      <>
        <AnalyticsHead />
        <MetaHead
          title={title || seoTitle || defaultTitle}
          description={description || defaultDescription}
        />
        <FacebookHead />
        <FontsHead />
        <AppStyle />
        <ErrorBoundary>
          <Provider store={store}>
            <div className={`app-shell ${isLandingPage ? 'landing-page' : 'not-landing-page'}`}>
              <AlertHandler />
              <ErrorHandler />
              <Pageview />
              <Authentication admin={admin} secure={secure} url={url} />
              <Messaging />
              <SyncQueryParams />
              <PWA />
              <NotificationsSubscription />
              <PresenceSubscription />
              <SettingsSubscription />
              <SubscriptionsSubscription />
              <UserSubscription />

              <PrimaryAppBar title={title || defaultAppBarTitle} />
              <TemporaryDrawer beforeInstallEvent={this.state.beforeInstallEvent} />
              <Content>
                <div className="permanent-drawer">
                  {!isLandingPage && (
                    <PermanentDrawer beforeInstallEvent={this.state.beforeInstallEvent} />
                  )}
                </div>
                <div className="main-wrapper">
                  <main>{children}</main>
                </div>
              </Content>
            </div>
          </Provider>
        </ErrorBoundary>
        <FirebaseScripts firebaseEnv={this.state.environment.firebase} />
        <SentryScripts />
        {algolia && <AlgoliaScripts />}
        {/* <Mailerlite /> */}
      </>
    );
  }
}

export default withRouter(AppShell);
