import React from 'react';
import StructuredData from '../head/structured-data';
import Footer from '../footers/footer';

export default function({ children, data }) {
  return (
    <>
      {data && <StructuredData data={data} />}
      {children}
      <Footer />
    </>
  );
}
