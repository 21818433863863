import React from 'react';
import MarkdownIt from 'markdown-it';
import markdownItAttrs from 'markdown-it-attrs';
import Paper from '../paper/paper';

import './markdown.css';

const md = new MarkdownIt({
  html: true,
}).use(markdownItAttrs);

export default class Markdown extends React.Component {
  render() {
    const { markdown } = this.props;
    let __html = md.render(markdown);

    return (
      <Paper>
        <div className="markdown" style={{ padding: '1rem 2rem' }}>
          <div dangerouslySetInnerHTML={{ __html }} />
        </div>
      </Paper>
    );
  }
}
