export default function downloadUri(uri) {
  const link = document.createElement('a');
  const name = uri.split('/').pop();

  link.download = name;
  link.href = uri;
  link.target = '_blank';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
