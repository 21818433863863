import React from 'react';
import Head from 'next/head';

export default () => {
  return (
    <Head>
      <script async src="https://www.googletagmanager.com/gtag/js" />
    </Head>
  );
};
