import React from 'react';
import Link from 'next/link';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';

import { DrawerHeader, DrawerContent } from 'rmwc/Drawer';
import { ListItem, ListItemText } from 'rmwc/List';
import { Icon } from 'rmwc/Icon';
import { Button } from 'rmwc/Button';

import '@material/button/dist/mdc.button.min.css';

import AccountIcon from '../user/account-icon';
import NotificationCountBubble from '../notifications/notification-count-bubble';

import './drawer-contents.css';

const publicLinks = [
  { href: '/public/articles', text: 'Articles' },
  { href: '/public/calligraphy-supplies-guide', text: 'Supplies' },
  { href: '/public/contact', text: 'Contact' },
  { href: '/public/faq', text: 'FAQ' },
  { href: '/public/calligraphy-workshops', text: 'Live Workshops' },
  { href: '/public/corporate-events', text: 'Private Events' },
];

export function DrawerContents({
  beforeInstallEvent,
  claims,
  currentUser,
  environment,
  notifications,
  pathname,
  subscriptions,
  handleSignOut,
  setIsDrawerOpen,
}) {
  const showModeratorMessages = claims && (claims.isAdmin || claims.isModerator);
  const messageNotifications = notifications.filter(
    ({ type }) => type == environment.notifications.MESSAGE
  );
  const messageNotificationsCount = messageNotifications.length;
  const pathnameWithSearch = typeof window == 'object' ? pathname + window.location.search : '';
  const { courseNames } = environment;

  return (
    <div className="drawer-contents" onClick={() => setIsDrawerOpen(false)}>
      <DrawerHeader>
        <div className="drawer-header">
          <AccountIcon currentUser={currentUser} />
          {currentUser && (
            <span className="display-name">{currentUser.displayName || currentUser.email}</span>
          )}
        </div>
      </DrawerHeader>

      <hr />

      <DrawerContent>
        <div>
          {claims && claims.isAdmin ? (
            <Active pathname={pathname} href="/admin">
              <Link href="/admin" prefetch>
                <ListItem>
                  <ListItemText>
                    <a>
                      <Icon icon="supervisor_account" />
                      <span>Admin</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          ) : null}
        </div>

        <div>
          {currentUser && (
            <Active pathname={pathname} href="/app/dashboard">
              <Link href="/app/dashboard" prefetch>
                <ListItem>
                  <ListItemText>
                    <a className="dashboard">
                      <Icon icon="dashboard" />
                      <span>Dashboard</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          )}
        </div>

        <div>
          {currentUser && subscriptions.length ? (
            <Active pathname={pathname} href="/app/messages">
              <Link href="/app/messages" prefetch>
                <ListItem>
                  <NotificationCountBubble count={messageNotificationsCount} />
                  <ListItemText>
                    <a className="messages">
                      <Icon icon="chat" />
                      <span>Messages</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          ) : null}
        </div>

        <div>
          {showModeratorMessages ? (
            <Active pathname={pathname} href="/admin/messages">
              <Link href="/admin/messages" prefetch>
                <ListItem>
                  <ListItemText>
                    <a className="feedback">
                      <Icon icon="feedback" />
                      <span>Moderation</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          ) : null}
        </div>

        {subscriptions.map(({ name, sku }) => {
          const href = `/app/class?classId=${sku}`;
          return (
            <Active pathname={pathnameWithSearch} href={href} key={sku}>
              <Link href={href} prefetch>
                <ListItem>
                  <ListItemText>
                    <a className="class">
                      <Icon icon="class" />
                      <span>{courseNames[sku]}</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          );
        })}

        <div>
          {currentUser ? (
            <Active pathname={pathname} href="/app/settings">
              <Link href="/app/settings" prefetch>
                <ListItem>
                  <ListItemText>
                    <a>
                      <Icon icon="settings" />
                      <span>Settings</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          ) : null}
        </div>

        <div>
          {!currentUser ? (
            <Active pathname={pathname} href="/login">
              <Link href="/login" prefetch>
                <ListItem>
                  <ListItemText>
                    <a>
                      <Icon icon="input" />
                      <span>Sign in</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          ) : null}

          {currentUser && (
            <ListItem onClick={signOut(handleSignOut)}>
              <ListItemText>
                <a tabIndex="0">
                  <Icon icon="power_settings_new" />
                  <span>Sign out</span>
                </a>
              </ListItemText>
            </ListItem>
          )}
        </div>

        <div>
          <a href="https://store.calligraphy.org/">
            <ListItem>
              <ListItemText>
                <Icon icon="store" />
                <span>Store</span>
              </ListItemText>
            </ListItem>
          </a>
        </div>

        <h4>Pages</h4>

        {publicLinks.map(({ href, text }, i) => (
          <div key={i}>
            <Active pathname={pathname} href={href}>
              <Link href={href} prefetch>
                <ListItem>
                  <ListItemText>
                    <a>
                      <span>{text}</span>
                    </a>
                  </ListItemText>
                </ListItem>
              </Link>
            </Active>
          </div>
        ))}

        <div>
          <a href="https://www.facebook.com/groups/384322728341174/">
            <ListItem>
              <ListItemText>
                <span>Facebook Group</span>
              </ListItemText>
            </ListItem>
          </a>
        </div>

        <div>
          <a href="https://legacy.calligraphy.org/">
            <ListItem>
              <ListItemText>
                <span>Legacy Calligraphy.org</span>
              </ListItemText>
            </ListItem>
          </a>
        </div>

        <div>
          {beforeInstallEvent && (
            <ListItem>
              <ListItemText>
                <Button
                  className="full-width"
                  raised
                  onClick={installToDesktop(beforeInstallEvent)}
                >
                  <Icon icon="get_app" />
                  <span>Install App</span>
                </Button>
              </ListItemText>
            </ListItem>
          )}
        </div>
      </DrawerContent>
    </div>
  );
}

export default connect(
  'beforeInstallEvent,claims,currentUser,environment,notifications,pathname,subscriptions',
  actions
)(DrawerContents);

function signOut(handleSignOut) {
  return async () => {
    await handleSignOut();
    window.firebase.auth().signOut();
  };
}

function Active({ href, pathname, children }) {
  return (
    <span className="active" disabled={pathname == href} onClick={e => e.preventDefault()}>
      {children}
    </span>
  );
}

function installToDesktop(e) {
  return () => {
    e.prompt();

    e.userChoice.then(({ outcome }) => {
      if (outcome == 'dismissed') {
        Alert('Installation rejected');
      } else {
        Alert('Installating...');
      }
    });
  };
}
