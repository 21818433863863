import React from 'react';

export default ({ firebaseEnv }) => {
  return (
    <>
      {/* <script src="https://www.gstatic.com/firebasejs/5.8.5/firebase.js" /> */}
      <script src="https://www.gstatic.com/firebasejs/5.8.5/firebase-app.js" />
      <script src="https://www.gstatic.com/firebasejs/5.8.5/firebase-auth.js" />
      <script src="https://www.gstatic.com/firebasejs/5.8.5/firebase-messaging.js" />
      <script src="https://www.gstatic.com/firebasejs/5.8.5/firebase-firestore.js" />
      <script src="https://www.gstatic.com/firebasejs/5.8.5/firebase-database.js" />
      <script src="https://www.gstatic.com/firebasejs/5.8.5/firebase-storage.js" />
      <script src="/__/firebase/init.js" onError={handleError} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            if (typeof firebase == 'undefined') {
              throw new Error('Firebase SDK not detected.');
            } else {
              firebase.apps.length == 0 && firebase.initializeApp(${JSON.stringify(firebaseEnv)})
            }
          `,
        }}
      />
    </>
  );
};

function handleError(error) {
  console.error('error', error);
}
