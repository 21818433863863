/* globals firebase */
import React from 'react';
import { connect } from 'unistore/react';
import { actions } from '../../datastore';

import BaseSubscription from './base-subscription';

import subscribeSubscriptions from '../../database/subscriptions/subscribe-subscriptions';

export class SubscriptionsSubscription extends React.Component {
  get shouldSubscribe() {
    const { currentUser } = this.props;

    return !!currentUser && !!currentUser.uid;
  }

  get observable() {
    const { currentUser, environment } = this.props;

    return subscribeSubscriptions({ environment, uid: currentUser.uid });
  }

  getNext() {
    return subscriptions => {
      const skus = (subscriptions && subscriptions.skus) || {};
      const flatSkus = Object.keys(skus).map(sku => ({ sku, name: skus[sku] }));

      this.props.setSubscriptions(flatSkus);
    };
  }

  render() {
    return this.shouldSubscribe ? (
      <BaseSubscription
        name="subscriptions-subscription"
        getNext={this.getNext.bind(this)}
        observable={this.observable}
      />
    ) : null;
  }
}

export default connect(
  'currentUser,environment',
  actions
)(SubscriptionsSubscription);
