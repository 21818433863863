module.exports = function parseSearch(search) {
  return search
    .slice(1)
    .split('&')
    .reduce((obj, pair) => {
      const [key, value] = pair.split('=');
      obj[key] = decodeURI(value);
      return obj;
    }, {});
};
