import React from 'react';
import Link from 'next/link';

import './footer.css';

export default props => {
  return (
    <footer className="footer">
      <ul>
        <li>
          <a href="https://store.calligraphy.org/">Store</a>
        </li>
        <li>
          <Link href="/public/about-us" prefetch>
            <a>About Us</a>
          </Link>
        </li>
        <li>
          <Link href="/public/contact" prefetch>
            <a>Contact</a>
          </Link>
        </li>
        <li>
          <Link href="/public/policies" prefetch>
            <a>Policies</a>
          </Link>
        </li>
        <li>
          <Link href="/public/faq" prefetch>
            <a>FAQ</a>
          </Link>
        </li>
      </ul>
    </footer>
  );
};
